<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Filters
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Jenis UPT</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="jenisUptFilter"
            :options="jenisUptOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:jenisUptFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Kanwil</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="kanwilFilter"
            :options="kanwilOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => changeUPT(val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>UPT</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="uptFilter"
            :options="uptOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:uptFilter', val)"
          />
        </b-col>
         <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Jenis Aset</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="jenisAssetFilter"
            :options="jenisAssetOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:jenisAssetFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Status Aset</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="statusAssetFilter"
            :options="statusAssetOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:statusAssetFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
  },
  props: {
    jenisUptFilter: {
      type: [Number, null],
      default: null,
    },
    kanwilFilter: {
      type: [Number, null],
      default: null,
    },
    uptFilter: {
      type: [Number, null],
      default: null,
    },
    jenisAssetFilter: {
      type: [Number, null],
      default: null,
    },
    statusAssetFilter: {
      type: [Number, null],
      default: null,
    },
    jenisUptOptions: {
      type: Array,
      required: true,
    },
    kanwilOptions: {
      type: Array,
      required: true,
    },
    uptOptions: {
      type: Array,
    },
    jenisAssetOptions: {
      type: Array,
    },
    statusAssetOptions: {
      type: Array,
    },
  },
  methods: {
    changeUPT(value) {
      this.$emit('emitReceiverUPT', value)
      this.$emit('update:kanwilFilter', value)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
